
import { useContext, useEffect, useRef, useState } from 'react'
import MenuAdminDesktop from "./MenuAdminDesktop";
import { FirestoreContext } from './FirestoreProvider';
import AddImmobile from './AddImmobile';
import ImmobiliItem from './ImmobiliItem';
import Toast from './Toast';

const Immobili = () => {

    const { locations, getLocations } = useContext(FirestoreContext);
    const { contracts, retrieveContracts } = useContext(FirestoreContext);
    const { buildings, getBuildingsSearch } = useContext(FirestoreContext);
    const { flattenTypologies, retrieveFlattenTypologies } = useContext(FirestoreContext);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedTipology, setSelectedTipology] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [currentBuildings, setCurrentBuildings] = useState([]);

    const [hasMore, setHasMore] = useState(true);
    const [showList, setShowList] = useState(false);
    const [startAt, setStartAt] = useState(null);
    const listRef = useRef(null);

    const search = () => {
        setStartAt(null);
        setHasMore(true);
        setCurrentBuildings([]);
        loadBuildings(true);
    };

    const handleLocationChange = (e) => {
        setSelectedLocation(e.target.value);
    }

    const handleContractChange = (e) => {
        setSelectedContract(e.target.value);
    }

    const handleTipologyChange = (e) => {
        setSelectedTipology(e.target.value);
    }

    const showAddImmobile = () => {
        // loadBuildings()
        setShowAdd(!showAdd)
    }

    const loadBuildings = async (newSearch) => {
        let currentLocalita = null;
        if (selectedLocation !== "defaultLocation") {
            currentLocalita = selectedLocation;
        }

        let currentContract = null;
        if (selectedContract !== "defaultContract") {
            currentContract = selectedContract;
        }

        let currentTipologia = null;
        if (selectedTipology !== "defaultTipologia") {
            currentTipologia = selectedTipology;
        }
        const emptyBuildings = [];
        setCurrentBuildings(emptyBuildings);

        let localStartAt = null;
        if (newSearch === false) {
            localStartAt = startAt;
        }

        await getBuildingsSearch(currentLocalita, currentContract, currentTipologia, null, null, localStartAt, true)
            .then((buildingList) => {
                let app = [];
                if (newSearch === true) {
                    app = [...buildingList];
                } else {
                    app = [...currentBuildings, ...buildingList];
                    scrollBottom();
                }
                setCurrentBuildings(app);
                if (buildingList.length > 0) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
                setShowList(true);
                setStartAt(app[app.length - 1]);
            })
            .catch((err) => {
                setMessage(true, "Errore durante il recupero degli annunci");
            });
    }

    const scrollBottom = () => {
        setTimeout(() => {
            let trList = document.getElementsByTagName("tr");
            let offset = trList[trList.length - 1].offsetTop - 100;
            window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
        }, 50);
    }

    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const setMessage = (isError, message) => {
        setToastMessage(message);
        setIsError(isError);
        setShowToast(true);
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    useEffect(() => {
        getLocations();
        retrieveFlattenTypologies();
        retrieveContracts();
        // loadBuildings();
    }, [])

    const loadMore = () => {
        loadBuildings(false);
    }

    const loadFromEditBuilding = () => {

    }

    const removeDeletedBuilding = (id) => {
        let app = currentBuildings.filter(b => b.id !== id);
        setCurrentBuildings([...app]);
    }

    return (
        <>
            <MenuAdminDesktop />
            {showAdd &&
                <AddImmobile showAddImmobile={showAddImmobile}/>
            }
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <div className="flex flex-col items-center justify-center">
                <div className='w-xl font-bold text-xl text-claim text-center border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Immobili
                </div>
                <div className='w-xl flex flex-row'>
                    {/* searchbar */}
                    {/* location filter */}
                    <div className='w-[350px] flex flex-row m-[10px] ml-[0px]' key="locationsKey">
                        <select className='select' defaultValue="Località" onChange={handleLocationChange}>
                            <option key="defaultLocation" value="defaultLocation">Località</option>
                            {locations.map((location, key) => {
                                return (
                                    <option key={key} value={location.value}>{location.value}</option>
                                )
                            })}
                        </select>
                    </div>
                    {/* location filter */}
                    {/* contract filter */}
                    <div className='w-[350px] flex flex-row m-[10px]' key="contractsDivKey">
                        <select className='select' defaultValue="Contratto" key="contractsSelectKey" onChange={handleContractChange}>
                            <option value="defaultContract" key="defaultContract">Contratto</option>
                            {contracts.map((contract, key) => {
                                return (
                                    <option key={key} value={contract.id}>{contract.value}</option>
                                )
                            })}
                        </select>
                    </div>
                    {/* contract filter */}
                    {/* typology filter */}
                    <div className='w-[350px] flex flex-row m-[10px]'>
                        <select className='select' defaultValue="Tipologia" onChange={handleTipologyChange}>
                            <option key="defaultTipologia" value="defaultTipologia">Tipologia</option>
                            {flattenTypologies.flat().map((typology, key) => {
                                return (
                                    <option key={key} value={typology.value}>{typology.value}</option>
                                )
                            })}
                        </select>
                    </div>
                    {/* typology filter */}
                    <div className='w-[150px] flex flex-row m-[10px] ml-[0px]'>
                        <div className='h-auto m-[10px]'>
                            <button className='w-[150px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={search} >CERCA</button>
                        </div>
                    </div>
                    {/* searchbar */}
                </div>
                {/* table */}
                <div className='w-xl mb-[20px]'>
                    <table className='w-xl border border-site-light-grey3'>
                        <thead>
                            <tr className='text-claim text-[15px]'>
                                <th className='border border-site-light-grey3 w-[120px]'>IMMAGINE</th>
                                <th className='border border-site-light-grey3 w-[120px]'>INFORMAZIONI</th>
                                <th className='border border-site-light-grey3'>DESCRIZIONE</th>
                                <th className='border border-site-light-grey3 w-[100px]'>
                                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]' onClick={showAddImmobile} >AGGIUNGI</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-[12px] text-claim' ref={listRef}>
                            {currentBuildings.map((b) => {
                                return (
                                    <ImmobiliItem callbackRealod={loadBuildings} callbackDelete={removeDeletedBuilding} setMessage={setMessage} building={b} key={b.id} />
                                )
                            })}
                        </tbody>
                    </table>
                    {
                        (hasMore && showList) &&
                        <div className='cursor-pointer btn-orange w-[50%] text-center ml-[25%] text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px] mt-[5px]' onClick={loadMore}>Carica altri</div>
                    }
                </div>
                {/* table */}
            </div>
        </>
    );
};

export default Immobili;