import React, { useEffect } from 'react'
import SearchBar from './SearchBar'

const Amministrazione = () => {
    useEffect(() => {
        setTimeout(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, 50);
    }, [])

    return (
        <div className='flex  xl:flex-row flex-col justify-center items-center w-full mb-[30px] xl:items-start xl:mt-[30px]'>
            <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                <div className='font-bold ml-[20px] mr-[20px] text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Amministrazione Condomini
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Clara Carnazzola - Socio Anammi S494 mail: amministrazioni.carnazzola@gmail.com</p>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Letizia Pedrazzoli - Socio ANAMMI BA89 mail: amministrazioni.pedrazzoli@gmail.com</p>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-col text-[15px] text-claim items-start'>
                        <p className='text-justify'>Tel. 0342 452491</p>
                        <p className='text-justify'>Cell. 347 8528235</p>
                        <p className='text-justify'>Caspoggio (So) Via Vanoni 33</p>
                    </div>
                </div>
            </div>
            <SearchBar />
        </div>
    )
}

export default Amministrazione