
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Servizi from './components/Servizi';
import Contatti from './components/Contatti';
import PopoverPolicy from './components/PopoverPolicy';
import Privacy from './components/Privacy';
import Login from './components/Login';
import Logout from './components/Logout';
import { AuthProvider } from './AuthProvider';
import PrivateRoute from './PrivateRouter';
import Immobili from './components/Immobili';
import { FirestoreProvider } from './components/FirestoreProvider';
import Typologies from './components/Typologies';
import Location from './components/Location';
import Box from './components/Box';
import Annuncio from './components/Annuncio';
import Cerca from './components/Cerca';
import ChiSiamo from './components/ChiSiamo';
import Amministrazione from './components/Amministrazione';



function App() {

  return (
    <AuthProvider>
      <FirestoreProvider>
        <BrowserRouter>
          <Header />
          <div className=' md:h-[138px] h-[188px]'></div>
          <PopoverPolicy />
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/servizi" element={<Servizi />} />
            <Route path="/contatti" element={<Contatti />} />
            <Route path="/immobili" element={<PrivateRoute><Immobili /></PrivateRoute>} />
            <Route path="/tipologie" element={<PrivateRoute><Typologies /></PrivateRoute>} />
            <Route path="/localita" element={<PrivateRoute><Location /></PrivateRoute>} />
            <Route path="/box" element={<PrivateRoute><Box /></PrivateRoute>} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/annuncio/:id' element={<Annuncio />} />
            <Route path='/annuncio/' element={<Annuncio />} />
            <Route path='/cerca' element={<Cerca />} />
            <Route path='/chi-siamo' element={<ChiSiamo />} />
            <Route path='/amministrazione' element={<Amministrazione />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
